import { BlueberryData } from "./BlueberryData";

const dynamicReducer = {
    callback: null,
    listener: function (callback) {
        this.callback = callback;
    },
    propagateUpdate: function () {
        if (this.callback != null) this.callback();
    },
    initialize: function (intialData) {
        // const keys = Object.keys(intialData);
        for (const key in intialData) {
            BlueberryData[key] = intialData[key];
        }
    },
    getData: function () {
        return BlueberryData;
    },
    token: () => {},
    posts: function ({ fields }) {
        BlueberryData.posts = fields;

        this.propagateUpdate();
    },
    user: function ({ fields }) {
        BlueberryData.user = fields;

        this.propagateUpdate();
    },
    accountCredit: function ({ fields }) {
        BlueberryData.accountCredit = fields;

        this.propagateUpdate();
    },
    cart: function ({ fields }) {
        BlueberryData.cart = fields ? fields : {};
        this.propagateUpdate();
    },
    history: function ({ fields }) {
        BlueberryData.history = fields ? fields : {};
        this.propagateUpdate();
    },
    tags: function ({ fields }) {
        BlueberryData.tags = { ...BlueberryData.tags };
        BlueberryData.tags[fields.slug] = fields;
        this.propagateUpdate();
    },
    cartMap: function ({ fields }) {
        BlueberryData.cartMap = fields;
        this.propagateUpdate();
    },
    menus: function ({ fields }) {
        BlueberryData.menus = { ...BlueberryData.menus };
        BlueberryData.menus[fields.slug] = fields;

        this.propagateUpdate();
    },
    products: function ({ products, slug, page }) {
        if (BlueberryData.products[slug] == null) {
            BlueberryData.products[slug] = [];
        }
        BlueberryData.products[slug][parseInt(page) - 1] = products;
        this.propagateUpdate();
    },
    product: function ({ fields }) {
        BlueberryData.product = { ...BlueberryData.product };
        BlueberryData.product[fields.slug] = fields;
        this.propagateUpdate();
    },
    search: function ({ fields }) {
        BlueberryData.search = fields;

        this.propagateUpdate();
    },
    suggestions: function ({ fields }) {
        BlueberryData.suggestions = fields;

        this.propagateUpdate();
    },
    collections: function (fields) {
        BlueberryData.collections = { ...BlueberryData.collections };
        BlueberryData.collections[fields.slug] = fields;
        this.propagateUpdate();
    },
    brand: function (fields) {
        BlueberryData.brand = { ...BlueberryData.brand };
        BlueberryData.brand[fields.brand.slug] = fields;
        this.propagateUpdate();
    },
    brands: function ({ products, slug, page }) {
        const sg = slug ? slug : "null";
        if (BlueberryData.brands[sg] == null) {
            BlueberryData.brands[sg] = [];
        }
        BlueberryData.brands[sg][parseInt(page) - 1] = products;
        this.propagateUpdate();
    },
    recomendations: function ({ fields }) {
        BlueberryData.recomendations = fields ? fields : {};
        this.propagateUpdate();
    },
    itemToItem: function ({ fields }) {
        BlueberryData.itemToItem = fields ? fields : {};
        this.propagateUpdate();
    },
    crossSell: function ({ fields }) {
        BlueberryData.crossSell = fields ? fields : {};
        this.propagateUpdate();
    },
    stats: function ({ fields }) {
        BlueberryData.stats = fields;
        this.propagateUpdate();
    },
    childCollection: function (fields) {
        BlueberryData.childCollection = fields;
        this.propagateUpdate();
    },
    orders: function ({ fields, page }) {
        if (BlueberryData.orders == null) {
            BlueberryData.orders = [];
        }
        BlueberryData.orders = [...BlueberryData.orders];
        BlueberryData.orders[page - 1] = fields;

        const ordersMapped = {};
        BlueberryData.orders[page - 1].map((index) => {
            ordersMapped[index._id] = index;
        });

        BlueberryData.order = { ...BlueberryData.order, ...ordersMapped };

        this.propagateUpdate();
    },
    order: function ({ id, fields }) {
        BlueberryData.order = { ...BlueberryData.order };
        BlueberryData.order[id] = fields;

        this.propagateUpdate();
    },
    wishlists: function ({ fields }) {
        const newWishlists = fields.own.map((index) => index);
        const newWishlistsShared = fields.shared.map((index) => index);
        BlueberryData.wishlists = [...newWishlists, ...newWishlistsShared];

        this.propagateUpdate();
    },
    personalizedExperience: function ({ fields, type, tag }) {
        if (BlueberryData.personalizedExperience[type] == null)
            BlueberryData.personalizedExperience[type] = {};
        if (BlueberryData.personalizedExperience[type][tag] == null)
            BlueberryData.personalizedExperience[type][tag] = [];
        BlueberryData.personalizedExperience[type][tag] = [
            ...BlueberryData.personalizedExperience[type][tag],
            ...fields,
        ];

        this.propagateUpdate();
    },
    personalizedExperienceTags: function ({ fields, type, tag }) {
        if (BlueberryData.personalizedExperienceTags[type] == null)
            BlueberryData.personalizedExperienceTags[type] = {};
        if (BlueberryData.personalizedExperienceTags[type][tag] == null)
            BlueberryData.personalizedExperienceTags[type][tag] = [];
        BlueberryData.personalizedExperienceTags[type][tag] = fields;

        this.propagateUpdate();
    },
};

export default dynamicReducer;
