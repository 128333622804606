import React from "react";
import { LoadLayer, Progress } from "./style";
import Logo from "../Logo";

function LoadLayerComponent({ progress, loadingText }) {
    return (
        <LoadLayer>
            <div>
                <Logo />
            </div>
            <div>
                <Progress value={progress}></Progress>
            </div>
            <div>
                <p>{loadingText}...</p>
            </div>
        </LoadLayer>
    );
}

export default LoadLayerComponent;
