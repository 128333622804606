// import loadable from '@loadable/component'

// import LocalizedStrings from 'localized-strings';
import { es } from "./es.js";
import { en } from "./en.js";
import { pt } from "./pt.js";

// const es = loadable(() => import('./es'))
// const en = loadable(() => import('./en'))
// const pt = loadable(() => import('./pt'))

// import loadable from '@loadable/component'

// const es = loadable.lib(() => import('./es.js'))
// const en = loadable.lib(() => import('./en.js'))
// const pt = loadable.lib(() => import('./pt.js'))

export const lang = (expression, lang) => {
	switch (lang) {
		case "en":
			return en[expression];
		case "pt":
			return pt[expression];
		default:
			return es[expression];
	}
};

// export const lang = es;
