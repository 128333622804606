export const runtimeConfig =
    typeof window !== "undefined"
        ? {
              // client
              public_key: window.env ? window.env.public_key : "",
              apiUrl: window.env ? window.env.apiUrl : "",
              websocketUrl: window.env ? window.env.websocketUrl : "",
              public_key: window.env ? window.env.public_key : "",
              MAIN_MENU_SLUG_LOGIN_ENG: window.env.MAIN_MENU_SLUG_LOGIN_ENG,
              MAIN_MENU_SLUG_LOGIN_PT: window.env.MAIN_MENU_SLUG_LOGIN_PT,
              MAIN_MENU_SLUG_LOGIN: window.env.MAIN_MENU_SLUG_LOGIN,
              IS_NORITEX: window.env.IS_NORITEX,
              DEFAULT_COMPANY_ID: window.env.DEFAULT_COMPANY_ID,
              websiteName: window.env.IS_NORITEX ? "Noritex" : "Mayorea",

              isStandalone: false,
          }
        : {
              // server
              public_key: process.env.PUBLIC_KEY,
              apiUrl: process.env.SERVER_URL,
              websocketUrl: process.env.WEBSOCKET_URL,
              public_key: process.env.PUBLIC_KEY,
              MAIN_MENU_SLUG_LOGIN_ENG: process.env.MAIN_MENU_SLUG_LOGIN_ENG,
              MAIN_MENU_SLUG_LOGIN_PT: process.env.MAIN_MENU_SLUG_LOGIN_PT,
              MAIN_MENU_SLUG_LOGIN: process.env.MAIN_MENU_SLUG_LOGIN,
              IS_NORITEX: process.env.IS_NORITEX === "yes",
              DEFAULT_COMPANY_ID: process.env.DEFAULT_COMPANY_ID,
              websiteName:
                  process.env.IS_NORITEX === "yes" ? "Noritex" : "Mayorea",

              isStandalone: false,
          };

export default runtimeConfig;
