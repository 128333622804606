import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { LangConsumer } from "../context";

const InternalLink = (props) => {
    // class InternalLink extends React.PureComponent {

    const onClick = (e) => {
        if (props.preventClick) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (props.onClick)
            props.onClick({
                currentTarget: {
                    getAttribute: () => {
                        return props.title;
                    },
                },
            });
    };

    if (
        props.link != null &&
        (props.link.indexOf("http") > -1 ||
            props.link.indexOf("https") > -1 ||
            props.link.indexOf("#") > -1 ||
            props.link.indexOf("mailto:") > -1)
    ) {
        return (
            <a
                className={props.className}
                href={props.link}
                onClick={onClick}
                target={props.target}
            >
                {props.children}
            </a>
        );
    }

    let to = props.to;

    if (to === "" || to == null) {
        if (
            props.slug != null &&
            props.type != null &&
            props.tag != null &&
            props.tag !== ""
        ) {
            to = `/${props.type}/${props.slug}/${props.tag
                .split(",")
                .join("+")}`;
        } else if (props.slug != null && props.type != null)
            to = `/${props.type}/${props.slug}`;
    }

    if (to == null && props.link != null) to = props.link;

    if (to == null) {
        return "Something is missing";
    }

    // lang consumer is declared in context and passed down from the main template
    return (
        <LangConsumer>
            {({ lang, brandSlug }) => (
                <NavLink
                    role={props.role ? props.role : "link"}
                    className={props.className}
                    activeClassName={props.activeClassName}
                    to={`/${lang}${
                        brandSlug && !props.ignoreBrand ? `/${brandSlug}` : ""
                    }${to}${to || to.indexOf("?") > -1 === "/" ? "" : "/"}`}
                    style={props.style}
                    onClick={onClick}
                    onMouseEnter={props.onMouseEnter}
                    onMouseLeave={props.onMouseLeave}
                    replace={props.replace}
                >
                    {props.children}
                </NavLink>
            )}
        </LangConsumer>
    );
};

InternalLink.defaultProps = {
    lang: "es",
};

export default React.memo(InternalLink);
