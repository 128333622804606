import { useState, useEffect } from "react";
import { BlueberryData } from "../BlueberryData";

function useLoggedIn() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (BlueberryData.user && BlueberryData.user.id) setIsLoggedIn(true);
    }, [BlueberryData.user]);
    return isLoggedIn;
}

export default useLoggedIn;
