export default () => {
    self.onmessage = (e) => {
        // eslint-disable-line no-restricted-globals
        if (!e) return;
        switch (e.data.do) {
            case "total":
                let carts = e.data.carts;

                let itemsMapped = {};

                for (let j = 0; j < carts.length; j++) {
                    for (let i = 0; i < carts[j].items.length; i++) {
                        if (
                            carts[j].items[i].quantity &&
                            carts[j].items[i].price != null &&
                            carts[j].items[i].quantity !== "" &&
                            carts[j].items[i].quantity > 0
                        ) {
                            itemsMapped[carts[j].items[i].productId] =
                                carts[j].items[i].quantity;
                        }
                    }
                }

                postMessage({
                    do: e.data.do,
                    data: {
                        itemsMapped,
                    },
                });
                return;
        }
    };
};
