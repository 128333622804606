import "core-js/es/symbol";
import { loadableReady } from "@loadable/component";
import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { hydrateRoot } from 'react-dom/client';

// import "./less/criticalCss.css";
import Blueberry from "./blueberry";

import routes from "./routes";
const data = window._INITIAL_DATA_;
Blueberry.initData(window._INITIAL_BLUEBERRY_);

if (typeof Object.assign != "function") {
    Object.assign = function (target) {
        "use strict";
        if (target == null) {
            throw new TypeError("Cannot convert undefined or null to object");
        }

        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
            var source = arguments[index];
            if (source != null) {
                for (var key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }
        }
        return target;
    };
}

// OfflinePluginRuntime.install();

// OfflinePluginRuntime.install({
// 	onUpdating: () => {
// 		console.log("SW Event:", "onUpdating");
// 	},
// 	onUpdateReady: () => {
// 		console.log("SW Event:", "onUpdateReady");
// 		// Tells to new SW to take control immediately
// 		OfflinePluginRuntime.applyUpdate();
// 	},
// 	onUpdated: () => {
// 		console.log("SW Event:", "onUpdated");
// 		// Reload the webpage to load into the new version
// 		window.location.reload();
// 	},
//
// 	onUpdateFailed: () => {
// 		console.log("SW Event:", "onUpdateFailed");
// 	},
// });

function isIE() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/

    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
}

function browserSupportsAllFeatures() {
    if (isIE()) return false;
    if (typeof window === "undefined") return true;
    return (
        window.Promise &&
        window.fetch &&
        window.Symbol &&
        window.IntersectionObserver
    );
}

function main() {
    loadableReady(() => {
        hydrateRoot(
            document.getElementById("root"),
            <BrowserRouter>
                <App routes={routes} initialData={data} />
            </BrowserRouter>
        );
    });
}

function loadScript(src, done) {
    console.log("loading scripts");
    var js = document.createElement("script");
    js.src = src;
    js.onload = function () {
        console.log("Loaded Polyfills");
        done();
    };
    js.onerror = function () {
        done(new Error("Failed to load script " + src));
    };
    document.head.appendChild(js);
}

if (browserSupportsAllFeatures()) {
    console.log("browser supports all features");
    // Browsers that support all features run `main()` immediately.
    main();
} else {
    // All other browsers loads polyfills and then run `main()`.
    loadScript("/polyfill.js", main);
    // main();
}

if (module.hot) {
    module.hot.accept();
}

// if (process.env.NODE_ENV === "production") {
//     const options = {
//         responseStrategy: "cache-first",
//         autoUpdate: 604800000,
//     };
//     require("razzle-plugin-offline/lib/serviceWorker")(options);
// }

if (typeof window !== "undefined") {
    var rxLocal = /^\/(en|es|pt|static)/i;
    var currentUrl = window.location.pathname;
    if (!rxLocal.test(currentUrl)) {
        window.location.replace("/es/");
    }
}

// serviceWorker.register({
//     onUpdate: (registration) => {
//         console.log("Update content by showing reload notification");
//         try {
//             document.getElementById("reload-notification").style.display =
//                 "block";
//         } catch (err) {
//             console.log(err);
//         }
//     },
// });
