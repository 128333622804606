export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};
export const eraseCookie = (name) => {
    document.cookie = name + "=; Max-Age=-99999999;";
};

export const numberWithCommas = (x) => {
    if (x == null) return 0;
    return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getParameterByName = (name, url) => {
    if (!url) {
        url = typeof window !== "undefined" ? window.location.href : url;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getParameterByNameNoReplace = (name, url) => {
    if (!url) {
        url = typeof window !== "undefined" ? window.location.href : url;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2]);
};

export const getOptions = (
    multiple,
    available,
    check_availability,
    min_venta //solo se usa cuando el check_availability esta apagado 
) => {
    const options = [];
    let mvta = (min_venta ? min_venta : 0)

    if (check_availability) mvta = 0;

    for (var i = 0; i <= 20; i++) {
        const val = i * multiple + mvta;
        if (check_availability && available > 0 && val <= available) {
            options.push({ value: val, title: val });
        } else if (!check_availability && val >= min_venta) {
            options.push({ value: val, title: val });
        }
    }
    
    return options;
};

export function removeHtml(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "").trim();
}
export function getDate(dateObj) {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    // let dateObj = new Date();
    let month = monthNames[dateObj.getMonth()];
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    return month + "\n" + day + "," + year;
}
export function getYearsSinceToday(dateObj) {
    const now = new Date();
    let timeDifference = now.getFullYear() - dateObj.getFullYear();
    return timeDifference;
}

export const scrollElementIntoView = (element, behavior) => {
    try {
        let scrollTop = window.pageYOffset || element.scrollTop;

        const headerOutsideIframe =
            document.getElementById("permanentHeader").clientHeight;

        const finalOffset =
            element.getBoundingClientRect().top +
            scrollTop -
            headerOutsideIframe -
            20;

        window.parent.scrollTo({
            top: finalOffset,
            behavior: behavior || "smooth",
        });
    } catch (err) {}
};

export function isEmailValid(email) {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
export function stripLangDir(lang, pathname) {
    var url = `https://www.noritex.com${pathname}`;
    var RE = new RegExp("/" + lang + "/");
    return url.replace(RE, "/");
}

export function addLangDir(lang, pathname, baseUrl) {
    var url = `${baseUrl}`;
    var RE = new RegExp("(\\.com/)");
    return url.replace(RE, "$1" + lang + "/");
}
export function getUrls(pathname, lang) {
    const baseUrl = stripLangDir(lang, pathname);
    const enUrl = addLangDir("en", pathname, baseUrl);
    const ptUrl = addLangDir("pt", pathname, baseUrl);
    const esUrl = addLangDir("es", pathname, baseUrl);
    return { esUrl, enUrl, ptUrl };
}

export function getSEO() {
    // todo
}

export function getPredefinedOrder(isSearch) {
    if (isSearch) return "";
    const defaultOrder =
        typeof window === "undefined"
            ? "predefined-order-descending"
            : window.localStorage.getItem("order");

    if (defaultOrder != null) return defaultOrder;

    return "predefined-order-descending";
}

export function asyncForEach(array, callback) {
    return new Promise(async (resolve, reject) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
        resolve();
    });
}

export const getTag = (user) => {
    if (user == null || user.id == null || user.tags == null) return "hogar";
    if (user.tags.indexOf("hogar") > -1) return "hogar";
    if (user.tags.indexOf("navidad") > -1) return "navidad";
    if (user.tags.indexOf("escolar") > -1) return "escolar";
    if (user.tags.indexOf("religioso") > -1) return "religioso";
    if (user.tags.indexOf("wellness") > -1) return "wellness";
};

export const getImageUrl = (image) => {
    let src = null;
    if (image && image.img == null && image.length > 0) {
        src = image[0].url;
    } else if (image && image.img && image.img.length > 0) {
        src = image.img[0];
    }

    return src;
};

export const rules = [
    {
        have: ["hogar", "feria"],
        setting: "isInHomeFair",
    },
    {
        have: ["navidad", "feria"],
        setting: "isInChristmasFair",
    },
    {
        have: ["escolar", "feria"],
        setting: "isInSchoolFair",
    },
    {
        have: ["wellness", "feria"],
        setting: "isInWellnessFair",
    },
];

export const lookForObject = (array, field, value) => {
	for (let i = 0; i < (array || []).length; (i += 1)) {
		if (array[i][field] === value) return array[i];
	}
	return null;
};

export const getFileExtension = (filename) => {
	const parts = filename.split(".");
	return parts[parts.length - 1].toLowerCase();
};

export const isFileTypeofVideo = (filename) => {
	const ext = getFileExtension(filename);
	return ["mp4", "webm", "ogg", "mov"].includes(ext);
};
