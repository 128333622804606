export const pt = {
	searchPlaceholder:
		"O que você está buscando? Você pode procurar por descrição ou material",
	filters: "Filtros",

	myAccount: "Minha conta",
	inviteHeader: "Para aceitar o convite, digite sua nova senha.",
	enterPassword: "Digite sua nova senha.",
	passwordLowerCase: "A senha deve estar em minúscula",
	passwordUpperCase: "A senha deve ser maiúscula",
	passwordNumber: "A senha deve ter um número",
	password8Characters: "A senha deve ter mais de 8 caracteres",
	whantToBeDistributor: "Você quer ser um distribuidor?",
	getMoreInformation: "Solicite mais informações aqui",
	getMoreInformation2: "e saber ser nosso cliente",

	acceptGeo: "Aceitar",
	loginInfo:
		"Se você já é um cliente existente, efetue login com seu nome de usuário e senha:",
	loginLink: "Esqueceu sua senha?",
	resetPassword: "Redefina sua senha",
	enterNewPassword: "Digite sua nova senha.",
	resetPasswordInfo: "Enviaremos um e-mail para redefinir sua senha.",
	youMustEnter: "Você deve entrar com seu",
	youMustEnter2: "usuário para acessar a plataforma.",
	youMustEnter3:
		"Se você ainda não possui um usuário, solicite-o registrando-se em",
	edit: "Editar",
	updateCart: "Atualizar carrinho",

	fair: "Futuro",
	inventory: "Inventário",
	available: "Acessível",

	tutorial: "Tutorial",
	images: "Imagens",

	addToCart: "Adicionar ao Carrinho",
	addAll: "Adicionar tudo",
	addToCartDone: "Produto foi adicionado",
	country: "Pais",
	city: "Cidade",

	gotoHome: "Voltar à página inicial",

	directionSend: "Endereço para envio",
	directionSend2: "Endereço para envio 2",
	backToCart: "Voltar ao carrinho",
	confirmOrder: "Confirmar pedido",
	continueWithShipping: "Continuar com o envio",
	continueWithQuote: "Confirm Order",
	information: "Em formação",
	shippingAddress: "Endereço para envio",
	contactPhoneNumber: "Telefone de contato",
	firstName: "Primeiro nome",
	lastName: "Sobrenome",
	noProductsInCart: "Não existem produtos no seu carrinho",
	cart: "O seu carrinho",
	checkout: "Verificação de saída",
	cubicaje: "deslocamento",
	helpTextCart:
		"Prezado cliente, o valor mínimo do seu pedido para produtos da Fair deve ser de US $15.000. Para produtos de inventário, deve ser de US $ 5000. Caso o valor do pedido seja menor, entre em contato com o seu executivo de vendas antes de continuar.",
	fabricaLowHigh: "Fábrica: Baixa a Alta",
	fabricaHighLow: "Fábrica: Alto para Baixo",
	priceLowHigh: "Preço: Menor para Maior",
	priceHighLow: "Preço: alto a baixo",
	oldestNewest: "Antigo para Novo",
	newestOldest: "Novo para Antigo",
	successContact:
		"Obrigado por entrar em contato conosco em breve, alguém entrará em contato",
	conctactUs: "Contate-nos",
	comments: "Comentários ...",
	contactUsDes:
		"Fale Conosco, Noritex é uma loja on-line para comprar atacadista de decoração.",
	currentQuantity: "Quantidade atual",

	refresh: "Atualizar",
	quickView: "Olhada Rápida",

	// search
	searchResults: "Resultados da Pesquisa",
	seAllResults: "Veja todos os resultados",
	relatedProducts: "Produtos Relacionados",
	similarProducts: "Produtos Similar",

	// claimForm:
	company: "Company",
	claimForm: "Claim Form",

	orderNumber: "Order or Bill Number",
	position: "Position",
	sku: "Reference or SKU",
	descriptionOfIssue: "Description of Problem",
	subDescriptionOfIssue:
		"Enter the references with which you had problems below.",
	quantity: "Quantity",

	ourBrands: "Nossas Marcas",
	brands: "Marcas",
	yourBrands: "Your Brands",
	latestBrands: "Latest Brands",

	salesman: "Salesman",

	productImages: "Imagens do produto",

	collectionHelpText: "Prezado cliente, para solicitar uma cotação, você deve se <a href='/pt/account/login/'>registrar</a>.",
	similar: "Semelhante",

	comercialize: "Torne-se um revendedor",
	seeMore: "Mostre mais",
	seeLess: "Mostre menos",

	searchItemsInCart: "Procure itens no seu carrinho....",

	continue: "Continuar",

	leadFormTitle: "Inscreva-se para solicitar mais informações",
	productsOfInterest: "Em quais produtos você está interessado?",
	phoneNumber: "Teléfono",
	country: "País",
	city: "Ciudad",
	email: "Correo",
	nameOfCompany: "Nome da empresa",
	positionInCompany: "Que posição você ocupa?",
	typeOfBusiness: "Qual é o seu tipo de negócio?",
	website: "O site da sua empresa",
	importedBefore: "Você já importou antes?",
	direction: "Endereço físico de sua sede",

	browserError:
		"Detectamos que seu navegador não é Chrome ou Firefox. Não oferecemos suporte a nenhum outro navegador que não seja o chrome e o firefox. Recomendamos o uso do navegador suportado.",

	backSlide: "Coleção anterior",
	nextSlide: "Coleção seguinte",
	guidedExperience: "Experiência Guiada",
	beginGuidedExperience: "Comece a experiência guiada",
	ambientes: "Ambientes Interativos",
	ambientesHowTo:
		"Clique nos ambientes abaixo para ver os produtos nessa imagem.",
	seeAmbient: "Veja Ambiente",
	close: "Fechar",

	minQuantity: "Quantidade mínima",
	material: "Material",

	downloadImageHelp1:
		"Primer paso: coloque os números de referência nas primeiras colunas de todas as imagens que serão descarregadas. O máximo é de 1000 imagens por descarga.",
	downloadImageHelp2:
		"Segunda etapa: pressione o botão de download e aguarde o término do download.",
	new: "new",
	removeFromCart: "Remover do Carrinho",
	setWindowTop: "Volver al inicio",
	last30products: "Ultimos 100 produtos",
	register: "Registro",
	noInternet:
		"Parece que você não está conectado à Internet. Por favor verifique sua conexão com a internet e tente novamente.",

	allAmbients: "Todas as imagens",
	generarProforma: "Gerar Excel",
	processingRequest: "We are working on your request",
	title: "title",

	description: "Descrição",
	signUp: "Registro",
	featureRequest: "Solicitação de Recursos",
	viwed: "Visto",
	of: "do",

	errorContact: "Ups it looks like you are not a real human",
	quote: "Cotação",
	shopByStyle: "Comprar por Lifestyle",
	showAdvancedSettings: "Opções Alterar resolução",
	downloadImages: "Download de Imagens",
	downloadImagesAmbient: "Baixe todas as imagens do produto",
	downloadZoom: "Download de Zoom",
	recomendedProducts: "Produtos Recomendados",
	tendencieTable: "Crie sua inspiração",
	comleteLook: "Complete seu visual",
	notAvailable: "Produto Esgotado",
	productsNoLongerAvailable:
		"Tos seguintes produtos não estão mais disponíveis",
	loginToViewPricing: "Inicie sesión para ver los precios",
	clientNumber: "Número do cliente",
	helpTextCartLogout:
		"Prezado cliente, muito obrigado pelo interesse em citar nossos produtos de última tendência, ao se cadastrar um de nossos novos executivos entrará em contato com você nas próximas horas para acompanhá-lo em seu pedido.",
	helpUsImprove: "Ajude-nos a melhorar",
	helpUsImprovePar: "Responda a uma breve pesquisa sobre a visita de hoje",
	begin: "Começar",
	feriaTag: "Próxima Feira de Decoração do Lar",
	orderByReference: "Ordem por referência",
	orderByCollection: "Ordem por coleção",
	orderByPrice: "Ordem por preço",
	menu: "Cardápio",

	// account
	store: "My Store",
	saveChanges: "Save Changes",
	other: "Other",
	neightborhodStore: "Neighborhood Store",
	bookStore: "Book Stores",
	departmentStore: "Department Store",
	hardwareStore: "Hardware Store",
	superMarket: "Super Market",
	smallDetailers: "Small Detailers",
	farmacies: "Farmacies",
	furnitureStore: "Furniture Store",
	wholesaler: "Wholesaler",
	homeDecorStore: "Home Decor Store",
	profile: "Perfil",

	personalDetails: "Detalhes pessoais",
	region: "Região",
	socialAccounts: "Mídia social",
	security: "Segurança",
	currentPassword: "Senha Atual",
	newPassword: "Nova Senha",
	reports: "Relatórios",
	requestStatementOfAccount: "Solicitar extrato de conta",
	send: "Enviar",

	// new translations
	dimensions: "Dimensões",
	collection: "Collection",
	material: "Material",
	packaging: "Embalagem",
	innerPack: "Inner Pack",
	unitOfMeasure: "Unidade de medida",
	weight: "Peso",
	next: "Próximo",
	back: "Ultimo",

	contactInformation: "Informações de Contato",
	clientInformation: "Informação ao Cliente",
	method: "Método",
	shippingMethod: "Método de envio",
	ship: "Remessa",
	payment: "Forma de pagamento",
	subtotal: "Subtotal",
	estimatedTaxes: "Impostos estimados",
	sendToPlaner: "Abrir no Planer",
	searchForOrderOrProduct:
		"Pesquise seus pedidos ou produtos dentro de seus pedidos...",
	order: "Ordem",
	date: "Encontro",
	dueDate: "Vencimento",
	total: "Total",
	subtotal: "Subtotal",
	items: "Itens",
	myOrders: "Minhas Ordens",
	myClaims: "Minhas Reclamações",
	despachados: "Shipped",
	quotations: "Citações",
	confirmados: "Confirmado",
	all: "Ver tudo",
	restock: "Reabastecer",
	relevancy: "Relevância",
	requestData: "Dados E-Comércio",
	price: "Preco",
	name: "Nome",
	saveToMyList: "Salvar na minha lista",
	select: "Selecione",
	myFirstList: "Minha primeira lista",
	learMoreAboutShops: "Integre com sua plataforma de comércio eletrônico",

	// novo 3
	confirmDeleteList: "Tem certeza de que deseja excluir a lista?",
	confirmEmptyList: "Tem certeza que deseja esvaziar a lista?",
	delete: "Delete",
	cancel: "Cancelar",
	empty: "Vazio",
	myList: "My Lists",
	listHelp: "Crie listas para organizar e planejar sua loja",
	invitationSend: "Convite enviado",
	wishlistEmptied: "Lista vazia",
	noPermisionsLIst: "O erro não tem permissões para esta lista",
	addedAllToCart: "Pronto, produtos adicionados ao carrinho.",
	wishlistDeleted: "List Deleted",
	share: "compartilhe",

	proformaGenerada: "Feito! Proforma Gerado",
	imagesRequested: "Feito! As imagens serão enviadas por email.",
	dataRequested: "Feito! Em alguns minutos você receberá os dados por e-mail",
	orderNotFound: "Pedido não encontrado",
	noCustomTemplateSetup:
		"Você não tem uma configuração de modelo personalizado em sua conta",
	backToOrders: "Voltar para Meus Pedidos",

	// novo 5
	noOrdersYet: "Você ainda não tem pedidos ...",
	noOrdersYetHelp: "Parece que você ainda não fez um pedido",
	noListYet: "Você ainda não tem listas ...",
	noListYetHelp: "Parece que você ainda não criou nenhuma lista",
	listEmpty: "Lista vazia",
	listEmptyHelp: "Comece a salvar seus produtos favoritos",
	somethingWengWrong: "Algo deu errado!",
	sorryYouEndedUpHere:
		"Lamentamos que você tenha parado aqui. Por favor, tente outra pesquisa:",
	home: "Página principal",
	goto: "Ir para",

	// novo 6
	noPermissionToThisBrand: "Você não tem permissão para esta marca",
	noPermissionToThisBrandHelp:
		"Para solicitar permissão, escreva para o representante de vendas atribuído",
	history: "História",
	latestNews: "Últimas Notícias",
	latestLists: "Listas criadas recentemente",
	articlesThatYouMighLike: "Artigos que podem interessar a você",
	navigationHistory: "Navigation History",
	recentlyViewedProducts: "Produtos que você viu recentemente",
	adminSection: "Admin Section",
	estasEn: "Você está dentro",
	feria: "Futuro",
	inventario: "Inventário",
	support: "Suporte",

	export: "Exportar",
	excelBy: "Proforma por",
	orderDetail: "Detalle de la orden",
	quantityOfItems: "itens Cantidad",

	save: "Guardar",
	price: "Precio",
	comments: "Comentários",
	addComments: "Agregar Comentarios",
	editComments: "Editar Comentarios",
	notAvailableAnymore: "Productos no disponibles",
	removeAll: "Quitar todos",
	confirmEmptyRemoved:
		"Estes seguros que decesas emprestam a lista de produtos removidos",
	confirmEmptyCart:
		"Estas seguro que decesas borrar todos los productos del carrito?",
	emptyCart: "Carrito vacío",
	orderBy: "Ordenar por",
	added: "Agregado",
	boxes: "Não. Bultos",
	photo: "Foto",

	noProductsFound: "Nenhum produto foi encontrado nesta pesquisa",
	searchNotFound: "Pesquisa não encontrada",

	fallowUs: "Siga-nos",
	yes: "Sim",
	no: "não",
	registerHere: "Assine aqui",
	ourClientsBelieve: "Nossos clientes dizem",

	ecommercePlatform: "Plataforma de comércio eletrônico",
	whatIsYourEcommercePlatform:
		"Selecione sua plataforma de comércio eletrônico?",

	profileUpdated: "Perfil Atualizado",

	ourCategories: "Nossas categorias",
	ourCategoriesHelp: "Veja nossas categorias mais recentes",
	clearFilters: "Limpar Filtros",

	allBrands: "Você poderá ver todas as suas marcas",
	skip: "Pular",
	searchBar: "Barra de pesquisa",
	searchBarHelp:
		"Você pode encontrar qualquer produto que estiver procurando",
	addProductsToCart: "Adicionar produtos ao carrinho",
	wishlistHelp: "Crie listas de produtos e trabalhe por coleção ou categoria",
	ordersHistory: "Histórico de pedidos",
	ordersHistoryHelp: "Verifique seu histórico de pedidos",
	tendencieTableHelp: "Crie sua inspiração em nosso planejador",
	done: "Concluir",
	clickHereToEnlarge: "Clique para visualização ampliada",
	help: "Ajuda",
	shoByCategory: "Compre por Categoria",
	sale: "Desconto",
	gotoCart: "Vá para o carrinho",

	caseOf: "Caso de",

	escolar: "De volta à escola",
	hogar: "Casa",
	wellness: "Wellness",
	religioso: "Religioso",
	navidad: "Natal",
	confirmOrderLogout: "Solicitar Cotação",
	allDepartments: "Todos os departamentos",
	departments: "Departamentos",

	cookiePolicy: "Cookie Policy",
	cookiePolicy2:
		"We use cookies (and other similar technologies) to collect data to improve your experience on our site. By using our website, you’re agreeing to the collection of data as described in our",
	settings: "Settings",
	inCart: "No Carrinho",
	seeProductInExibit: "Veja o produto exposto",
	color: "Cor",
	videoRequested: "Video Requested",
	requestVideo: "Request Video",
	request: "Request",
	informationRequested: "Information Requested",
	generatePdf: "Generate PDF",
	search: "Procurar",

	priceStore: "Store Price",
	configure: "Configure",
	configureStorePrice: "Set store price",
	adjustMultiplier:
		"Adjust your multiplier and currency conversion (Noritex Price X Multiplier X Currency Exchange = $ Your Price)",
	timesNoritex: "Multiplier your noritex price will be multiplied",
	currency: "Currency",
	currencyExchange: "Currency exchange",
	multiplier: "Multiplier",
	yourPrice: "Your Price",
	conversion: "Conversion",
	helpPriceStore: "Calculate the price in your store",

	volCapacity: "Cap. Volumétrica",
	mecanismo: "Mecanismo",
	notasEspeciales: "Especiais",
	otherNotes: "Outras Notas",
	error: "Error pelase try again later",
	reachedMaximunRequest:
		"Ups! You can only have 10 open product data requests",

	errorFirstName: "Please enter a name",
	errorLastName: "Please enter a last name",
	errorEmail: "Please enter a valid email",
	errorPhoneNumber: "Please enter a valid contact number",
	errorCountry: "Please enter a valid country",
	errorCity: "Please enter a valid city",
	errorCompanyName: "Please enter your company name",
	errorCompanyPosition:
		"Please enter the name of your position in the company",
	errorCompanyType: "Please enter the type of business",
	errorCompanyWebsite:
		"Please enter the company's website if you have. If you don't have, please write: we don't have",
	errorCompanyType: "Please select business type",
	errorOption: "Please select an option",
	errorAddress: "Please enter a full address",
	errorProducts: "Please enter your products of interest",
	readyToBuyProductsOnline: "Ready to start buying wholesale online?",
	registerToBuy: "Registre-se",
	unique1: "Exclusive products designed for all tastes",
	unique3: "Find high-quality products for your store",
	unique2: "Innovative and unique products for your store",
	imageSearch: "Image Search",
	basedOnYourHistory: "Com base no seu histórico de pedidos, preparamos as seguintes recomendações para você",
	backToInfo: "Back to Shipping",
	continueWithPayments: "Continua to Payments",
	updateCartHappened:
		"Oops! It seems that your cart had an update, you want to check it before continuing?",
	pleaseContactSalesrepToConfirm:
		"Please contact your salesrep to confirm your order",
	direct: "Direct",
	hasProxy:
		"It looks like you have a proxy installed, our website is not compatible with proxies. Please disable our proxy before entering your website or contact your IT department to have it disabled.",
	transit: "Transit",
	requestAccess: "Request Brand Access",
	pendingBrandRequest: "Pending Brand Request",
	deliveryDate: "Delivery Date",
	products: "products",
	pleseLoginToEnter: "Faça login para adicionar ao carrinho",
	win200: "Ganhar $2000",
	isMissingImages: "Fotos a serem carregadas",
	unitPrice: "Preço unitário",
	questionTitle: "Perguntas e respostas do cliente",
	leaveAQuestion: "Deixe uma pergunta",
	sendAQuestion: "Enviar pergunta",
	question: "Perguntar",
	response: "Resposta",
	pleaseAddQuestion: "Digite sua pergunta...",
	"No realizo": "Eu não importo",
	exw: "EXW",
	fob: "FOB",

	claimExplain: "Para enviar uma reclamação, selecione produtos",
	claimExplainExpired:
		"Sua fatura está vencida, pois as reclamações devem ser feitas dentro do tempo estipulado na Política de Reclamações e Despacho. Em caso de dúvidas, por favor, entre em contato com seu vendedor.",
	alreadyClaimed: `Esta fatura já tem uma reclamação`,
	searchProductInOrder: "Busca de produto, favor inserir a referência",
	"Recibe estos beneficios y más": "o que nos destaca",
	"Ver todos los beneficios": "Veja todos os benefícios",
	billed: "Faturado",
	invoice: "Fatura",
	"Account Status": "Status da Conta",
	Date: "Data",
	"Customer Information": "Informações do Cliente",
	Name: "Nome",
	"Nº CLIENT": "Nº CLIENTE",
	"Payment Terms": "Condições de pagamento",
	Address: "Endereço",
	Phone: "Telefone",
	Email: "Email",
	"Credit limit": "Limite de crédito",
	Seller: "Vendedor",
	"Account statement details": "Detalhes do extrato da conta",
	"Expiration Date": "Data de vencimento",
	Type: "Tipo",
	"Billed Days": "Dias faturados",
	"Business Unit": "Unidade de negócios",
	Bill: "Fatura",
	Procedure: "Procedimento",
	Order: "Ordem",
	Description: "Descrição",
	Debit: "Débito",
	Credit: "Crédito",
	Balance: "Saldo",
	Total: "Total",
	"Reserved orders": "Pedidos reservados",
	Account: "Conta",
	"Traditional amount": "Quantidade tradicional",
	"Brand amount": "Quantidade da marca",
	"Accounts receivable aging": "Envelhecimento de contas a receber",
	"Payment instructions": "Instruções de pagamento",
	"Account Status Footer":
		"É responsabilidade de cada cliente verificar o extrato da sua conta no momento do recebimento, salvo notificação em contrário no prazo de quinze (15) dias após a data do extrato, esta conta será considerada correta. Por favor, leve em consideração os saldos credores mantidos por mais de 2 anos na conta, o saldo será limpo.",
	Legend: "Legenda",
	"Current Account": "Conta Corrente",
	"Beneficiary Client": "Cliente Beneficiário",
	"Intermediary Bank": "Intermediary Bank",
	"Beneficiary Bank": "Banco Beneficiário",
	"It seems this link has expired. Request a new link or get in touch with the seller for assistance.":
		"Parece que este link expirou. Solicite um novo link ou entre em contato com o vendedor para obter assistência.",
	"Try refreshing again. If the information still doesn't appear, feel free to contact your seller.":
		"Tente atualizar novamente. Se as informações ainda não aparecerem, sinta-se à vontade para entrar em contato com seu vendedor.",
	claimExplain: "Para enviar uma reclamação, selecione produtos",
	claimExplainExpired:
		"Sua fatura está vencida, pois as reclamações devem ser feitas dentro do tempo estipulado na Política de Reclamações e Despacho. Em caso de dúvidas, por favor, entre em contato com seu vendedor.",
	alreadyClaimed: `Esta fatura já tem uma reclamação`,
	searchProductInOrder: "Busca de produto, favor inserir a referência",
	"Ver todos los beneficios": "Veja todos os benefícios",
	invoice: "Fatura",
	discountsByBrand: "Filtre descontos por marca",
	"N/C": "Nota de crédito",
	Factura: "Fatura",
	Recibos: "Recibos",
	CUENTA: "CONTA",
	"MONTO TRADIC": "QUANTIDADE TRADICIONAL",
	"MONTO MARCA": "MARCA PRÓPIA",
	"N/D": "Nota de débito",
	Refresh: "Atualizar",
	"NTXBRAND-100": "HOGAR",
	"NTXBRAND-110": "NAVIDAD",
	"NTXBRAND-120": "RELIGIOSO",
	"NTXBRAND-150": "WELLNESS",
	"NTXBRAND-200": "CRISTALERIA",
	"NTXBRAND-300": "TEXTIL",
	"NTXBRAND-400": "DENIM",
	"NTXBRAND-500": "ESCOLAR Y OFICINA",
	"Outstanding balance": "Saldo pendente",
	"Overdue balance": "Saldo vencido",
	"Featured categories Title": "Produtos que Definem Tendências",
	"Featured categories": "Categorias em destaque",
	"Categories that may also interest you.":
		"Categorias que também podem interessar-lhe.",
	noQuestionsYet: "Ainda não há perguntas",

	// leadForm changes
	entrepreneurship: "Empreendedorismo",
	contactOptions: "Como você prefere ser contactado?",
	whatsappChat: "Chat do WhatsApp",
	whatsappCall: "Chamada do WhatsApp",
	howFoundUs: "Como você nos encontrou?",
	referredByCustomer: "Indicação de Cliente",
	howFoundUsPlaceholder: "Por favor, especifique",
	conocenos: "Conheça-nos",
	links: {
		title: "Acessos Diretos",
		l1: "Kit de Marketing",
		l2: "Download de Imagens",
		l3: "Perguntas Frequentes",
		l4: "Monte sua Coleção",
		l5: "Guias e Tutoriais",
	},
	featuredBlog: "Visite Nosso Blog de Conteúdo B2B",
	recommendationsTitle: "Produtos que você pode gostar",
};
