export const en = {
	searchPlaceholder:
		"What are you looking for? You can search for description or material",
	filters: "Filters",

	comercialize: "Sign Up",
	myAccount: "My Account",
	inviteHeader: "To accept the invite please enter your new password.",
	enterPassword: "Please enter your new password.",
	passwordLowerCase: "Password must have a lowercase",
	passwordUpperCase: "Password must have an uppercase",
	passwordNumber: "Password must have a number",
	password8Characters: "Password must be longer than 8 characters",
	whantToBeDistributor: "Ready to Explore Our Product Range?",
	getMoreInformation: "Start your journey with Noritex by signing up here.",
	getMoreInformation2:
		"Once registered, you'll gain access to our extensive selection of quality products.",

	acceptGeo: "Accept",
	loginInfo:
		"If you are already an existing customer, log in with your username and password:",
	loginLink: "Did you forget your password?",
	resetPassword: "Reset your password",
	enterNewPassword: "Enter new password.",
	resetPasswordInfo: "We will send you an email to reset your password",
	youMustEnter: "You must enter with your",
	youMustEnter2: "user to access the platform.",
	youMustEnter3:
		"If you do not have a user yet, request it by registering at",

	// cart
	edit: "Edit",
	similar: "Similar",
	updateCart: "Update Cart",

	removeFromCart: "Remove From Cart",
	currentQuantity: "Current Quantity",

	refresh: "Refresh",
	quickView: "Quick View",
	searchItemsInCart: "Search for items in your cart....",

	fair: "Future",
	inventory: "Inventory",
	available: "Available",

	tutorial: "Tutorial",
	images: "Images",
	dropImages: "Please enter one or images for the product",

	// product details
	addToCart: "Add to Cart",
	addAll: "Add all to Cart",
	addToCartDone: "Product added to cart",
	relatedProducts: "Related Products",
	similarProducts: "Similar Products",
	recomendedProducts: "Recommended Productos",

	// checkout
	country: "Country",
	city: "City",

	gotoHome: "Go to Home",
	direction: "Direccion de envio",
	directionSend: "Shipping Address",
	directionSend2: "Shipping Address 2",
	continueWithQuote: "Confirm Order",
	information: "Information",
	continueWithShipping: "Continue with shipping",
	backToCart: "Back to Cart",
	confirmOrder: "Confirm Order",
	shippingAddress: "Shipping Address",
	contactPhoneNumber: "Contact Phone Number",
	firstName: "First Name",
	lastName: "Last Name",

	contactInformation: "Contact information",
	clientInformation: "Customer information",
	method: "Method",
	shippingMethod: "Method of Shipping",
	ship: "Shipping",
	payment: "Payment",

	estimatedTaxes: "Estimated taxes",

	// cart
	noProductsInCart: "There are no products in your cart",
	cart: "Your Cart",
	checkout: "Checkout",
	cubicaje: "Cubic Meter",
	helpTextCart:
		"Dear customer, the minimum amount of your order for Fair products must be USD 10,000. For inventory products it must be USD 5000. In case the order amount is lower, please contact your sales executive before to continue. ",

	//collection
	fabricaLowHigh: "Factory: Low to High",
	fabricaHighLow: "Factory: High to Low",
	priceLowHigh: "Price: Low to High",
	priceHighLow: "Price: High to Low",
	oldestNewest: "Oldest to Newest",
	newestOldest: "Newest to Oldest",

	// contact us
	successContact:
		"Thank you for contacting us, someone will be in contact shortly.",
	conctactUs: "Contact Us",
	comments: "Comments...",
	contactUsDes:
		"Contact Us, Noritex is an online wholesale store to buy decoration, home decor, furniture, and more.",

	// search
	searchResults: "Search Results",
	seAllResults: "See all results",

	// claimForm:
	company: "Company",
	claimForm: "Claim Form",

	orderNumber: "Order or Bill Number",
	position: "Position",
	sku: "Reference or SKU",
	descriptionOfIssue: "Description of Problem",
	subDescriptionOfIssue:
		"Enter the references with which you had problems below.",
	quantity: "Quantity",

	ourBrands: "Our Brands",
	brands: "Brands",
	yourBrands: "Your Brands",
	latestBrands: "Latest Brands",

	salesman: "Salesman",

	productImages: "Product Images",

	collectionHelpText: "Dear customer, to request a quote, you must <a href='/en/account/login/'>register</a>.",

	seeMore: "Show More",
	seeLess: "Show Less",

	continue: "Continue",

	leadFormTitle: "Sign up to request more information",
	productsOfInterest: "Which products interest you?",
	phoneNumber: "Phone Number",
	country: "Country",
	city: "City",
	email: "Email",
	nameOfCompany: "Name of company",
	positionInCompany: "Position in company",
	typeOfBusiness: "Type of business",
	website: "Company website",
	importedBefore: "How do you do you import?",
	direction: "Physical address of your parent company",

	browserError:
		"We have detected that your browser is not Chrome or Firefox. We dont support any other browser than chrome and firefox. We recommend using the supported browser.",

	backSlide: "Last Collection",
	nextSlide: "Next Collection",
	guidedExperience: "Guided Experience",
	beginGuidedExperience: "Begin Guided Experience",

	ambientes: "Interactive Spaces",
	ambientesHowTo:
		"Click on the images below to see the products in the image selected.",
	seeAmbient: "See Products",
	close: "Close",

	minQuantity: "Minimun Quantity",
	material: "Material",

	downloadImageHelp1:
		"First step: Place the reference numbers on the first columns of all the images you want to download. The maximun is 1000 images per download.",
	downloadImageHelp2:
		"Select the option of your preference, press download and wait for the download to finish.",
	new: "new",
	setWindowTop: "Back to Top",
	last30products: "Ultimos 100 productos",
	register: "Register",
	noInternet:
		"It looks like you are not connected to the internet. Please check your internet connection and try again.",

	allAmbients: "All Images",
	generarProforma: "Generarate Excel",
	processingRequest: "We are working on your request",
	title: "title",

	description: "Description",
	singUp: "Sign Up",
	featureRequest: "Feature Request",
	viwed: "Viewed",
	of: "of",

	errorContact: "Ups it looks like you are not a real human",
	quote: "Get a Quote",
	shopByStyle: "Shop by Lifestyle",
	showAdvancedSettings: "Change Image Resolution",
	downloadImages: "Download Images",
	downloadImagesAmbient: "Download All Product Images",
	downloadZoom: "Download de Zoom",
	tendencieTable: "Build your Inspiration",
	comleteLook: "Complete your look",
	notAvailable: "Unavailable",
	productsNoLongerAvailable:
		"The following products are not longer available",
	loginToViewPricing: "Login to view pricing",
	clientNumber: "Client Number",
	helpTextCartLogout:
		"Dear customer, thank you very much for your interest in quoting our products. You are only one step away from getting your quote.",
	helpUsImprove: "Help us improve",
	helpUsImprovePar: "Take a short survey about today's visit",
	begin: "Start",
	feriaTag: "Upcoming Home Decor Expo",
	orderByReference: "Order by Reference",
	orderByCollection: "Order by Collection",
	orderByPrice: "Order by Price",
	minVentaAsec: "Minimum Quantity Lowest",
	minVentaDesc: "Minimun Quantity Highest",
	menu: "Menu",

	// account
	store: "My Store",
	saveChanges: "Save Changes",
	other: "Other",
	neightborhodStore: "Neighborhood Store",
	bookStore: "Book Stores",
	departmentStore: "Department Store",
	hardwareStore: "Hardware Store",
	superMarket: "Super Market",
	smallDetailers: "Small Detailers",
	farmacies: "Farmacies",
	furnitureStore: "Furniture Store",
	wholesaler: "Wholesaler",
	homeDecorStore: "Home Decor Store",

	profile: "Profile",
	personalDetails: "Personal Details",
	region: "Region",
	socialAccounts: "Social Media",
	security: "Security",
	currentPassword: "Current Password",
	newPassword: "New Password",
	reports: "Reports",
	requestStatementOfAccount: "Request account statement",
	send: "Send",

	// new translations
	dimensions: "Dimensions",
	collection: "Collections",
	material: "Material",
	packaging: "Packaging",
	innerPack: "Inner Pack",
	unitOfMeasure: "Unit of Measure",
	weight: "Weight",
	next: "Next",
	back: "Back",

	searchForOrderOrProduct:
		"Search your orders or products inside your orders...",
	order: "Order",
	date: "Date",
	dueDate: "Due Date",
	total: "Total",
	subtotal: "Subtotal",
	items: "Items",
	myOrders: "My Orders",
	myClaims: "My Claims",
	quotations: "Quotations",
	confirmados: "Confirmed",
	despachados: "Shipped",

	sendToPlaner: "Send to Planner",
	all: "View All",
	addAClaim: "Place a Claim",
	uploadToEcommerce: "Upload to my Ecommerce",
	restock: "Restock",
	learMoreAboutShops: "Integrate with your ecommerce platform",
	discoverHere: "Discover Here",
	relevancy: "Relevance",
	requestData: "Download Ecommerce Data",
	price: "Price",
	name: "Name",
	saveToMyList: "Save to my list",
	select: "Select",
	myFirstList: "My First List",

	// new 3
	confirmDeleteList: "Are you sure you want to delete the list?",
	confirmEmptyList: "Are you sure you want to empty the list?",
	delete: "Delete",
	cancel: "Cancel",
	empty: "Empty",
	myList: "My Lists",
	listHelp: "Create lists to organize and plan your store",
	invitationSend: "Invitation sent",
	wishlistEmptied: "Empty List",
	noPermisionsLIst: "Error does not have permissions for this list",
	addedAllToCart: "Ready, products added to cart.",
	wishlistDeleted: "List Deleted",
	share: "Share",

	proformaGenerada: "Done! Excel generated",
	imagesRequested: "Done! Images will be send via email.",
	dataRequested: "Done! In a few minutes you will receive the data via email",
	orderNotFound: "Order not found",
	noCustomTemplateSetup:
		"Error: You don't a custom template setup in your account",
	backToOrders: "Back to My Orders",

	// new 5
	noOrdersYet: "You don't have orders yet...",
	noOrdersYetHelp: "Looks like you haven't placed any orders yet",
	noListYet: "You don't have any lists yet...",
	noListYetHelp: "Looks like you haven't created any lists yet",
	listEmpty: "List Empty",
	listEmptyHelp: "Start saving your favorite products",
	somethingWengWrong: "Something went wrong!",
	sorryYouEndedUpHere:
		"We're sorry you endded up here. Please try another search:",
	home: "Home Page",
	goto: "Go to",

	// nuevo 6
	noPermissionToThisBrand: "You do not have permission for this brand",
	noPermissionToThisBrandHelp:
		"To request permission please write to your assigned salesrep",
	history: "History",
	latestNews: "Latest News",
	latestLists: "Recently created lists",
	articlesThatYouMighLike: "Articles that may interest you",
	navigationHistory: "Navigation History",
	recentlyViewedProducts: "Products you have recently viewed",
	adminSection: "Admin Section",
	estasEn: "You are in",
	feria: "Future",
	inventario: "Inventory",
	support: "Support",

	export: "Export",
	excelBy: "Proforma by",
	orderDetail: "Order detail",
	quantityOfItems: "Quantity items",

	save: "Save",
	price: "Price",
	comments: "Comments",
	addComments: "Add Comments",
	editComments: "Edit Comments",
	notAvailableAnymore: "Products not available",
	removeAll: "Remove all",
	confirmEmptyRemoved:
		"Are you sure you want to delete the list of unavailable items",
	confirmEmptyCart:
		"Are you sure you want to delete all the products from your cart?",
	emptyCart: "Empty cart",
	orderBy: "Order by",
	added: "Added",
	boxes: "No. Packages",
	photo: "Photo",

	noProductsFound: "No products where found in this search",
	searchNotFound: "Products not found",

	fallowUs: "Fallow Us",
	yes: "Yes",
	no: "No",
	registerHere: "Register Here",
	ourClientsBelieve: "Our Clients Opinions",

	ecommercePlatform: "Ecommerce Platform",
	whatIsYourEcommercePlatform: "Select your ecommerce platform?",

	profileUpdated: "Profile Updated",

	ourCategories: "Our Categories",
	ourCategoriesHelp: "Checkout our latest categories",
	clearFilters: "Clear Filters",

	allBrands: "You will be able to see all your brands",
	skip: "Skip",
	searchBar: "Search Bar",
	searchBarHelp: "You can find any product you are looking for",
	addProductsToCart: "Add products to cart",
	wishlistHelp: "Create lists of products and work by collection or category",
	ordersHistory: "Order history",
	ordersHistoryHelp: "Check your order history",
	tendencieTableHelp: "Create your inspiration in our planner",
	done: "Finish",
	clickHereToEnlarge: "Click here to view in full screen",
	help: "Help",
	shoByCategory: "Shop by Category",
	sale: "Sale",
	gotoCart: "Go to Cart",

	caseOf: "Case of",

	escolar: "Back to School",
	hogar: "Home",
	wellness: "Wellness",
	religioso: "Religious",
	navidad: "Christmas",
	confirmOrderLogout: "Request Quote",

	allDepartments: "All Departments",
	departments: "Departments",

	cookiePolicy: "Cookie Policy",
	cookiePolicy2:
		"We use cookies (and other similar technologies) to collect data to improve your experience on our site. By using our website, you’re agreeing to the collection of data as described in our",
	settings: "Settings",
	inCart: "In Cart",
	seeProductInExibit: "See Product Exhibited",
	color: "Color",
	videoRequested: "Video Requested",
	requestVideo: "Request Video",
	request: "Request",
	informationRequested: "Information Requested",
	generatePdf: "Generate PDF",
	search: "Search",

	priceStore: "Store Price",
	configure: "Configure",
	configureStorePrice: "Set store price",
	adjustMultiplier:
		"Adjust your multiplier and currency conversion (Noritex Price X Multiplier X Currency Exchange = $ Your Price)",
	timesNoritex: "Multiplier your noritex price will be multiplied",
	currency: "Currency",
	/* Creating a variable called currencyExchange and assigning it the value of a function. */
	currencyExchange: "Currency exchange",
	multiplier: "Multiplier",
	yourPrice: "Your Price",
	conversion: "Conversion",
	helpPriceStore: "Calculate the price in your store",

	volCapacity: "Vol. Capacity",
	mechanism: "Mechanism",
	notasEspeciales: "Special Features",
	otherNotes: "Other Notes",
	error: "Error pelase try again later",
	reachedMaximunRequest:
		"Ups! You can only have 10 open product data requests",

	errorFirstName: "Please enter a name",
	errorLastName: "Please enter a last name",
	errorEmail: "Please enter a valid email",
	errorPhoneNumber: "Please enter a valid contact number",
	errorCountry: "Please enter a valid country",
	errorCity: "Please enter a valid city",
	errorCompanyName: "Please enter your company name",
	errorCompanyPosition:
		"Please enter the name of your position in the company",
	errorCompanyType: "Please enter the type of business",
	errorCompanyWebsite:
		"Please enter the company's website if you have. If you don't have, please write: we don't have",
	errorCompanyType: "Please select business type",
	errorOption: "Please select an option",
	errorAddress: "Please enter a full address",
	errorProducts: "Please enter your products of interest",
	readyToBuyProductsOnline: "Ready to start ordering wholesale online?",
	registerToBuy: "Sign Up",
	unique1: "Exclusive products designed for all tastes",
	unique3: "Find high-quality products for your store",
	unique2: "Innovative and unique products for your store",
	imageSearch: "Image Search",
	basedOnYourHistory: "Based on your order history, we have prepared the following recommendations for you",
	backToInfo: "Back to Shipping",
	continueWithPayments: "Continua to Payments",
	updateCartHappened:
		"Oops! It seems that your cart had an update, you want to check it before continuing?",
	pleaseContactSalesrepToConfirm:
		"Please contact your salesrep to confirm your order",
	direct: "Direct",
	hasProxy:
		"It looks like you have a proxy installed, our website is not compatible with proxies. Please disable your proxy before entering our website or contact your IT department to have it disabled.",
	transit: "Transit",
	requestAccess: "Request Brand Access",
	pendingBrandRequest: "Pending Brand Request",

	deliveryDate: "Delivery Date",
	products: "products",
	pleseLoginToEnter: "Log in to add to cart",
	win200: "Win $2000",
	isMissingImages: "Pending photos to be uploaded",
	unitPrice: "Unit price",
	questionTitle: "Customer questions and answers",
	leaveAQuestion: "Leave a question",
	sendAQuestion: "Submit Question",
	question: "Question",
	response: "Response",
	pleaseAddQuestion: "Type your question...",
	"No realizo": "I don't import",
	exw: "EXW",
	fob: "FOB",

	claimExplain: "To send a claim, select products",
	claimExplainExpired:
		"Your invoice is pastdue, as claims must be made within the time stipulated in the Claims and Dispatch Policy. If you have any doubts, please contact your salesperson.",
	alreadyClaimed: `This invoice already has a claim`,
	searchProductInOrder: "Search for product, enter the reference",
	"Recibe estos beneficios y más": "what sets us apart",
	"Ver todos los beneficios": "See all benefits",
	billed: "Billed",
	invoice: "Invoice",
	"Account Status": "Account Status",
	Date: "Date",
	"Customer Information": "Customer Information",
	Name: "Name",
	"Nº CLIENT": "Nº CLIENT",
	"Payment Terms": "Payment Terms",
	Address: "Address",
	Phone: "Phone",
	Email: "Email",
	"Credit limit": "Credit limit",
	Seller: "Seller",
	"Account statement details": "Account statement details",
	"Expiration Date": "Expiration Date",
	Type: "Type",
	"Billed Days": "Billed Days",
	"Business Unit": "Business Unit",
	Bill: "Bill",
	Procedure: "Procedure",
	Order: "Order",
	Description: "Description",
	Debit: "Debit",
	Credit: "Credit",
	Balance: "Balance",
	Total: "Total",
	"Reserved orders": "Reserved orders",
	Account: "Account",
	"Traditional amount": "Traditional amount",
	"Brand amount": "Brand amount",
	"Accounts receivable aging": "Accounts receivable aging",
	"Payment instructions": "Payment instructions",
	"Account Status Footer":
		"It is the responsibility of each client to verify their account statement upon receipt, unless otherwise notified within fifteen (15) days following the date of the statement, this account will be considered correct. Please, take into consideration credit balances that are maintained for more than 2 years in the account, the balance will be cleaned.",
	Legend: "Legend",
	"Current Account": "Current Account",
	"Beneficiary Client": "Beneficiary Client",
	"Intermediary Bank": "Intermediary Bank",
	"Beneficiary Bank": "Beneficiary Bank",
	"It seems this link has expired. Request a new link or get in touch with the seller for assistance.":
		"It seems this link has expired. Request a new link or get in touch with the seller for assistance.",
	"Request Error": "Request Error",
	"Try refreshing again. If the information still doesn't appear, feel free to contact your seller.":
		"Try refreshing again. If the information still doesn't appear, feel free to contact your seller.",
	discountsByBrand: "Filter discounts by brand",
	"N/C": "Credit note",
	Factura: "Invoice",
	Recibos: "Receipts",
	CUENTA: "ACCOUNT",
	"MONTO TRADIC": "TRADITIONAL AMOUNT",
	"MONTO MARCA": "PRIVATE BRAND",
	"N/D": "Debit note",
	Refresh: "Refresh",
	"NTXBRAND-100": "HOGAR",
	"NTXBRAND-110": "NAVIDAD",
	"NTXBRAND-120": "RELIGIOSO",
	"NTXBRAND-150": "WELLNESS",
	"NTXBRAND-200": "CRISTALERIA",
	"NTXBRAND-300": "TEXTIL",
	"NTXBRAND-400": "DENIM",
	"NTXBRAND-500": "ESCOLAR Y OFICINA",
	"Outstanding balance": "Outstanding balance",
	"Overdue balance": "Overdue balance",
	"Featured categories Title": "Trendsetting Products",
	"Featured categories": "Featured categories",
	"Categories that may also interest you.":
		"Categories that may also interest you.",
	noQuestionsYet: "No questions yet",

	// leadForm changes
	entrepreneurship: "Entrepreneurship",
	contactOptions: "How do you prefer to be contacted?",
	whatsappChat: "WhatsApp Chat",
	whatsappCall: "WhatsApp Call",
	howFoundUs: "How did you find us?",
	referredByCustomer: "Referred by Customer",
	howFoundUsPlaceholder: "Please specify",
	conocenos: "Get to know us",
	links: {
		title: "Shortcuts",
		l1: "Marketing Kit",
		l2: "Image Download",
		l3: "Frequently Asked Questions",
		l4: "Build Your Collection",
		l5: "Guides and Tutorials",
	},
	featuredBlog: "Visit Our B2B Content Blog",
	recommendationsTitle: "Recommended For You",
};
