/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
// import { createClass } from 'asteroid';
import simpleDDP from "simpleddp"; // ES6
import ws from "isomorphic-ws";
import { config } from "../config";

const { simpleDDPLogin } = require("simpleddp-plugin-login");
// import { setCookie } from "./utils"

class asteroid {
	constructor(isServer = true) {
		this.isServer = isServer;
		this.asteroid = null;
		this.callbacks = [];
	}

	_init(callback) {
		if (this.isServer) {
			// return this.asteroid;
		}
		if (callback != null) this.callbacks = [callback];
		if (this.asteroid != null) {
			return this.asteroid;
		}
		// console.log(config.websocketUrl)
		const opts = {
			// endpoint: process.env.WEBSOCKET_URL ? process.env.WEBSOCKET_URL  : 'wss://ntxcms.herokuapp.com/websocket',
			endpoint:
				config.websocketUrl !== ""
					? config.websocketUrl
					: window.env && window.env.websocketUrl !== ""
					? window.env.websocketUrl
					: "wss://ntxcms.herokuapp.com/websocket",
			SocketConstructor: ws,
			reconnectInterval: 3000,
			maxTimeout: 30000,
		};

		this.asteroid = new simpleDDP(opts, [simpleDDPLogin]);

		// const server = new simpleDDP(opts);
		return this.asteroid;
	}

	index() {
		return this.callbacks.length - 1;
	}

	getAsteroid() {
		return this.asteroid;
	}
}

const ast = new asteroid(typeof window === "undefined");
export default ast;
