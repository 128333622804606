import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { themeLight, themeDark } from "./theme";

import { GlobalCss } from "./GlobalCss";
import * as serviceWorker from "./serviceWorker";
import { getCookie } from "./js/utils";
import { initializeAnalytics } from "./js/components/Footer2/components/ConsentManager";
import WhatsappButton from "./js/components/WhatsappButton";

function trackAnalytics() {
    React.useEffect(() => {
        if (getCookie("ck-consent") == "yes") {
            initializeAnalytics();
        }
    }, []);
}

class SWContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newVersionAvailable: false,
            waitingWorker: {},
        };
    }

    componentDidMount() {
        caches.keys().then((cacheNames) => {
            if (cacheNames && cacheNames.length > 1) {
                this.setState({ newVersionAvailable: true });
            }
        });
    }

    onServiceWorkerUpdate = (registration) => {
        this.setState({
            waitingWorker: registration && registration.waiting,
            newVersionAvailable: true,
        });
    };

    updateServiceWorker = () => {
        const { waitingWorker } = this.state;
        waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
        this.setState({ newVersionAvailable: false });

        caches.keys().then((cacheNames) =>
            Promise.all(
                cacheNames.map((cacheName) => {
                    return caches.delete(cacheName);
                })
            ).then(() => {
                navigator.serviceWorker
                    .getRegistrations()
                    .then((registrations) => {
                        for (let registration of registrations) {
                            registration.unregister();
                        }
                        window.location.reload(true);
                    });
            })
        );
    };

    refreshAction = (key) => {
        //render the snackbar button
        return (
            <div
                style={{
                    backgroundColor: "rgba(0,0,0,0.7)",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100vh",
                    zIndex: 99999,
                }}
            >
                <div
                    id="reload-notification"
                    style={{
                        backgroundColor: " #112c55",
                        boxShadow:
                            "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)",
                        position: "fixed",
                        bottom: "40%",
                        left: "calc(50% - 150px)",
                        padding: "10px",
                        color: "#fff",
                        width: "100%",
                        maxWidth: "300px",
                        maxHeight: "500px",
                        zIndex: 99999,
                    }}
                >
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="exclamation-circle"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="50px"
                        style={{
                            margin: "10px auto",
                            display: "block",
                            color: "white",
                        }}
                        height="50px"
                    >
                        <g className="fa-group">
                            <path
                                fill="currentColor"
                                d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 376a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 265.52 288h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 233.68 128h44.64a16 16 0 0 1 15.92 17.59z"
                                className="fa-secondary"
                            />
                            <path
                                fill="black"
                                d="M278.32 128h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 246.48 288h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 278.32 128zM256 320a32 32 0 1 0 32 32 32 32 0 0 0-32-32z"
                                className="fa-primary"
                            />
                        </g>
                    </svg>
                    Una nueva versión de nuestra aplicación está disponible,
                    vuelva a cargar la página.
                    <a
                        id="reload-sw"
                        onClick={this.updateServiceWorker}
                        style={{ cursor: "pointer" }}
                    >
                        {" "}
                        Refrescar
                    </a>
                    <br />
                    <br />A new version of our application is available, reload
                    the page.
                    <a
                        id="reload-sw"
                        onClick={this.updateServiceWorker}
                        style={{ cursor: "pointer" }}
                    >
                        {" "}
                        Refresh / Reload
                    </a>
                </div>
            </div>
        );
    };

    componentDidMount = () => {
        // if (process.env.NODE_ENV === "production") {
        serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });
        // }
    };

    render() {
        const { newVersionAvailable } = this.state;
        if (newVersionAvailable) return this.refreshAction();
        return null;
    }
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        if (typeof window !== "undefined" && analytics)
            analytics.track("Error Reporting", {
                error,
                errorInfo,
            });
        this.setState({ hasError: true });
    }

    render() {
        return this.props.children;
    }
}

function usePageViews() {
    let location = useLocation();
    React.useEffect(() => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
            if (analytics) analytics.page();
        }
    }, [location]);
}

const App = ({ routes, initialData }) => {
    const [theme, setTheme] = React.useState("light");
    const themeToggler = () => {
        theme === "light" ? setTheme("dark") : setTheme("light");
    };
    usePageViews();
    trackAnalytics();
    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme === "light" ? themeLight : themeDark}>
                <Switch>
                    {routes.map((route, index) => {
                        // pass in the initialData from the server or window.DATA for this
                        // specific route
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) =>
                                    React.createElement(route.component, {
                                        ...props,
                                        hasBackButton: route.hasBackButton,
                                        privateTemplate: route.private,
                                        themeToggler,
                                        theme,
                                        // also declared in hook
                                        jsonSettings: initialData
                                            ? initialData[
                                                  initialData.length - 2
                                              ]
                                            : null,
                                        menusData: initialData
                                            ? initialData[
                                                  initialData.length - 1
                                              ]
                                            : [],
                                        initialData: initialData
                                            ? initialData[index]
                                            : null,
                                    })
                                }
                            />
                        );
                    })}
                    <Route exact path="/">
                        <Redirect to="/es/" />
                    </Route>
                </Switch>
                <GlobalCss />
                <SWContainer />
                <WhatsappButton />

                {/* <ConsentManager /> */}
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export default App;
