import { useContext } from "react";
import { lang } from "../config";
import { LangContext } from "../../js/context";

function useTranslation() {
	// const [text, setText] = useState(null);
	const context = useContext(LangContext);

	const _t = (expression) => {
		return lang(expression, context.lang) || expression;
	};

	// useEffect(() => {}, [context.lang]);

	return [_t, context.lang];
}

export default useTranslation;
