export const BlueberryData = {
    token: {
        id: "",
        token: "",
    },
    posts: [],
    user: {},
    cart: [],
    tags: {},
    cartMap: {},
    menus: {},
    products: {},
    product: {},
    search: {
        products: [],
    },
    suggestions: [],
    brands: {},
    collections: {},
    brand: {},
    recomendations: {
        products: [],
    },
    itemToItem: {
        products: [],
    },
    crossSell: {
        products: [],
    },
    stats: {
        total: 0,
        count: 0,
        cbm: 0,
        count: 0,
        lastProduct: {},
    },
    childCollection: [],
    orders: [],
    order: {},
    wishlists: [],
    productMap: {},
    personalizedExperience: {
        hogar: {},
        navidad: {},
        escolar: {},
    },
    personalizedExperienceTags: {
        hogar: {},
        navidad: {},
        escolar: {},
    },
};
