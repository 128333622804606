export const es = {
	searchPlaceholder:
		"¿Que estás buscando? Puede buscar descripción, colección, categorías o material",
	filters: "Filtros",

	comercialize: "Registrate",
	myAccount: "Mi Cuenta",
	inviteHeader: "Para aceptar la invitacion ingrese su nueva contraseña.",
	enterPassword: "Ingrese su nueva contraseña.",
	passwordLowerCase: "La contraseña debe tener minúsculas",
	passwordUpperCase: "La contraseña debe tener mayúscula",
	passwordNumber: "La contraseña debe tener un número",
	password8Characters: "La contraseña debe tener más de 8 caracteres",
	whantToBeDistributor: "¿Quieres Registrarte?",
	getMoreInformation: "Solicita más información aquí",
	getMoreInformation2: "y conoce cómo crear una cuenta",

	acceptGeo: "Aceptar",
	loginInfo:
		"Si ya eres un cliente existente, ingresa con tu usuario y contraseña:",
	loginLink: "Olvidé mi contraseña",
	resetPassword: "Restablecer su contraseña",
	enterNewPassword: "Ingrese su nueva contraseña.",
	resetPasswordInfo:
		"Le enviaremos un correo electrónico para restablecer su contraseña.",
	youMustEnter: "Debes ingresar con tu",
	youMustEnter2: "usuario para poder acceder a la plataforma.",
	youMustEnter3: "Si aún no tienes usuario, solicítalo registrandote en",

	// cart
	edit: "Editar",
	similar: "Relacionados",
	updateCart: "Actualizar Carrito",
	removeFromCart: "Remover del Carrito",

	currentQuantity: "Cantidad Actual",

	refresh: "Refrescar",
	searchItemsInCart: "Busca artículos en tu carrito....",

	fair: "Futuro",
	inventory: "Inventario",
	available: "Disponible",

	tutorial: "Tutoriales",
	images: "Imagenes",
	dropImages:
		"Suba las imágenes a continuación. Por favor ingrese las imagenes del producto",

	// product details
	addToCart: "Anadir a la Cesta",
	addAll: "Agregar todos al Carrito",
	addToCartDone: "Producto ha sido agregado",
	quickView: "Vista Rapida",
	relatedProducts: "Productos Relacionados",
	similarProducts: "Productos Similares",
	recomendedProducts: "Productos Recomendados",

	// checkout
	country: "Pais",
	city: "Ciudad",

	gotoHome: "Volver a la Tienda",
	directionSend: "Dirección de Envío",
	directionSend2: "Casa Numero de Oficina... (Opcional)",
	confirmOrder: "Confirmar pedido",
	confirmOrderLogout: "Solicitar Cotización",
	shippingAddress: "Dirección de Envío",
	contactPhoneNumber: "Teléfono de contacto",
	firstName: "Primer nombre",
	lastName: "Apellido",
	name: "Nombre",

	contactInformation: "Información de contacto",
	clientInformation: "Información del cliente",
	method: "Método",
	shippingMethod: "Método de envío",
	ship: "Envíos",
	payment: "Pago",

	// Cart
	noProductsInCart: "No hay productos en su carrito",
	cart: "Su carrito",
	checkout: "Confirmar Pedido",
	cubicaje: "Cubicaje",
	helpTextCart:
		"Apreciado cliente: el monto mínimo de tu pedido para productos de Feria debe ser de USD 5.000. Para productos de inventario debe ser de USD 5000. En caso de que el monto de pedido sea inferior, por favor contacta a tu ejecutivo de ventas antes de continuar.",

	//collection
	fabricaLowHigh: "Fabrica: Bajo to Alto",
	fabricaHighLow: "Fabrica: Alto to Bajo",
	priceLowHigh: "Precio: Bajo to Alto",
	priceHighLow: "Precio: Alto to Bajo",
	oldestNewest: "Antiguedad Ascendente",
	newestOldest: "Antiguedad Descendiente",

	successContact:
		"Gracias por contactarnos prontamente alguien estara en contacto",
	conctactUs: "Contáctenos",
	comments: "Comentarios...",
	contactUsDes:
		"Contact Us, Noritex es una tienda en linea para comprar mayorista de decoracion.",

	// search
	searchResults: "Resultados de Busqueda",
	seAllResults: "Ver todos los resultados",

	// claimForm:
	company: "Empresa",
	claimForm: "Formulario de Reclamo",

	orderNumber: "Numero de Pedido o Factura",
	position: "Posición",
	sku: "Referencia",
	descriptionOfIssue: "Descripción del problema",
	subDescriptionOfIssue:
		"Ingrese las refernecias con los que ha tenido problemas a continuación.",
	quantity: "Cantidad",

	ourBrands: "Nuestras Marcas",
	brands: "Marcas",
	news: "Noticias",
	salesman: "Vendedor",
	yourBrands: "Tus Marcas",
	latestBrands: "Ultimas Marcas",

	productImages: "Imágenes de Productos",

	collectionHelpText: "Estimado cliente, para solicitar una cotización, debe <a href='/es/account/login/'>registrarse</a>.",

	seeMore: "Ver Más",
	seeLess: "Ver Menos",

	// leadForm

	continue: "Continuar",

	leadFormTitle: "Regístrate para solicitar más información",
	productsOfInterest: "¿Cuáles productos le interesan?",
	phoneNumber: "Teléfono",
	country: "País",
	city: "Ciudad",
	email: "Correo",
	nameOfCompany: "Nombre de la empresa",
	positionInCompany: "¿Cargo que ocupa?",
	typeOfBusiness: "¿Cuál es su tipo de negocio?",
	website: "Sitio web de su empresa",
	importedBefore: "¿Cómo hace sus importaciones marítimas?",
	direction: "Dirección física de su casa matriz",

	browserError:
		"Hemos detectado que su navegador no es Chrome o Firefox. No admitimos ningún otro navegador que no sea Chrome y Firefox. Recomendamos usar algun navegador compatible.",

	backSlide: "Coleccion Anterior",
	nextSlide: "Coleccion Siguiente",

	guidedExperience: "Experiencia Guiada",
	beginGuidedExperience: "Comienza Experiencia Guiada",

	ambientes: "Ambientes",
	ambientesHowTo:
		"Para hacer su selección y visualizar los productos de cada ambiente, por favor haga clic en las imágenes a continuación.",
	seeAmbient: "Ver Ambiente",
	close: "Cerrar",

	minQuantity: "Cantidad Minima",
	material: "Material",

	downloadImageHelp1:
		"Primer paso: coloque los números de referencia en la primera columna de todas las imágenes que desea descargar. El máximo es de 1000 imágenes por descarga.",
	downloadImageHelp2:
		"Seleccione la opción de su preferencia, presione descargar y espere a que finalice la descarga.",
	new: "Nuevo",
	setWindowTop: "Volver arriba",
	backToCart: "Volver al Carrito",
	continueWithShipping: "Continuar con el envío",
	continueWithQuote: "Confirmar",
	information: "Información",
	last30products: "Ultimos 100 productos",
	register: "Registrarse",
	noInternet:
		"Parece que no estás conectado a internet. Verifique su conexión a Internet e intente nuevamente.",

	allAmbients: "Todos los Ambientes",
	generarProforma: "Generar Proforma",
	processingRequest: "Estamos procesando tu respuesta",
	title: "Titulo",

	description: "Descripción",
	singUp: "Registrarse",
	featureRequest: "Solicitud de función",
	minVentaAsec: "Minimo de Venta Descendiente",
	minVentaDesc: "Minimo de Venta Ascendente",
	viwed: "Vistos",
	of: "de",

	errorContact: "Ups it looks like you are not a real human",
	quote: "Cotizar",
	shopByStyle: "Compra por estilos de vida",
	showAdvancedSettings: "Cambiar resolución de imagen",
	downloadImages: "Descarga Imágenes",
	requestData: "Descarga Ecommerce Data",
	downloadImagesAmbient: "Descarga todas las Imágenes",
	downloadZoom: "Descarga Zoom",
	tendencieTable: "Mesa de Tendencia",
	comleteLook: "Completa tu look",
	notAvailable: "Agotado",
	productsNoLongerAvailable:
		"Lamentamos informarte que no contamos con estos productos disponibles, te invitamos a buscar en nuestro extenso surtido.",
	loginToViewPricing: "Inicie sesión para ver precios",
	clientNumber: "Numero de Cliente",
	helpTextCartLogout:
		"Estimado cliente, muchas gracias por su interés en cotizar nuestros productos. Solo está a un paso de obtener su cotización.",
	helpUsImprove: "Ayudanos a Mejorar",
	helpUsImprovePar: "Contesta una breve encuenta sobre la visita de hoy",
	begin: "Empezar",
	feriaTag: "Próxima Feria de Decoración Hogar",
	// orderByReference: "Ordenar por Referencia",
	// orderByCollection: "Ordenar por Colección",
	// orderByPrice: "Ordenar por Price",
	menu: "Menú",

	// account
	store: "Mi Tienda",
	saveChanges: "Guardar Cambios",
	other: "Otros",
	neightborhodStore: "Tienda de Barrio",
	bookStore: "Librerias",
	departmentStore: "Tiendas por Departamento",
	hardwareStore: "Ferretería",
	superMarket: "Supermercado",
	smallDetailers: "Pequeños detallistas",
	farmacies: "Farmacias",
	furnitureStore: "Tienda de muebles",
	wholesaler: "Mayorista",
	homeDecorStore: "Tienda de decoración",

	profile: "Perfil",
	personalDetails: "Datos personales",
	region: "Region",
	socialAccounts: "Redes sociales",
	security: "Seguridad",
	currentPassword: "Contraseña actual",
	newPassword: "Contraseña nueva",
	reports: "Reportes",
	requestStatementOfAccount: "Solicitar estado de cuenta",
	send: "Enviar",

	// new translations
	dimensions: "Dimensiones",
	collection: "Colección",
	material: "Material",
	packaging: "Empaque",
	innerPack: "Inner Pack",
	unitOfMeasure: "Unidad de Medida",
	weight: "Peso",

	//nuevos 2
	searchForOrderOrProduct: "Busca tus ordenes o productos...",
	order: "Orden",
	date: "Fecha",
	dueDate: "Vencimiento",
	total: "total",
	subtotal: "Subtotal",
	items: "Items",
	myOrders: "Mis Ordenes",
	myClaims: "Mis Reclamos",
	quotations: "Cotizaciones",
	confirmados: "Confirmed",
	despachados: "Despachados",
	next: "Siguiente",
	back: "Anterior",
	sendToPlaner: "Mesa de Tendencia",
	all: "Ver Todo",
	addAClaim: "Poner un reclamo",
	uploadToEcommerce: "Subir a mi eCommerce",
	restock: "Resuplir",
	learMoreAboutShops: "Integrate con tu plataforma de Ecommerce ",
	discoverHere: "Descúbrelo aquí",
	estimatedTaxes: "Taxes Estimado",
	relevancy: "Relevancia",

	saveToMyList: "Guardar en mi lista",
	select: "Seleccione",
	myFirstList: "My First List",

	// nuevos 3
	confirmDeleteList: "Estas seguro que deseas eliminar la lista?",
	confirmEmptyList: "Estas seguro que deseas vaciar la lista?",
	delete: "Eliminar",
	cancel: "Cancelar",
	empty: "Vaciar",
	myList: "Mis Listas",
	listHelp: "Crea listas para organizar y planificar tu tienda",
	invitationSend: "Invitacion enviada",
	wishlistEmptied: "Lista Vaciada",
	noPermisionsLIst: "Error no tiene permisos para esta lista",
	addedAllToCart: "Listos, productos agregados al carrito.",
	wishlistDeleted: "Lista Borrada",
	share: "Compartir",

	// nuevo 4
	proformaGenerada: "Listo! Proforma generada",
	imagesRequested: "Listo! Imagenes seran enviadas por correo.",
	dataRequested: "Listo! En unos minutos recibirás los datos vía email",
	orderNotFound: "Orden no encontrada",
	noCustomTemplateSetup:
		"Error: No tiene una configuración de plantilla personalizada en su cuenta",

	backToOrders: "Volver a Mis órdenes",

	// nuevo 5
	noOrdersYet: "Aún no tiene órdenes...",
	noOrdersYetHelp: "Parece que aún no ha realizado ningún pedido",
	noListYet: "Aún no tiene Listas...",
	noListYetHelp: "Parece que aún no ha creado ningún lista",
	listEmpty: "Lista Vacia",
	listEmptyHelp: "Comience a guardar sus productos favoritos",
	somethingWengWrong: "¡Algo salió mal!",
	sorryYouEndedUpHere:
		"Lamentamos que haya parado aquí. Intente otra búsqueda:",
	home: "Página principal",
	goto: "Ir a",

	// nuevo 6

	noPermissionToThisBrandHelp:
		"Para solicitar permiso por favor escriba a su vendedor asignado",
	history: "Historial",
	latestNews: "Últimas Noticias",
	latestLists: "Listas creadas recientemente",
	articlesThatYouMighLike: "Artículos que te pueden interesar",
	navigationHistory: "Historial de Navegación",
	recentlyViewedProducts: "Productos que has visto recientemente",
	adminSection: "Admin Section",
	estasEn: "Estás en",
	feria: "Futuro",
	inventario: "Inventario",
	support: "Soporte",

	// neuvo 7
	export: "Exportar",
	excelBy: "Proforma por",
	orderDetail: "Detalle de la orden",
	quantityOfItems: "Cantidad items",

	save: "Guardar",
	price: "Precio",

	comments: "Comentarios",
	addComments: "Agregar Comentarios",
	editComments: "Editar Comentarios",
	notAvailableAnymore: "Productos no disponibles",
	removeAll: "Quitar todos",
	confirmEmptyRemoved:
		"Estas seguro que deseas borrar la lista de productos removidos",
	confirmEmptyCart:
		"Estas seguro que deseas borrar todos los productos del carrito?",
	emptyCart: "Carrito vacío",
	orderBy: "Ordenar por",
	added: "Agregado",
	boxes: "Bultos",
	photo: "Foto",

	noProductsFound: "No se encontraron productos en esta búsqueda",
	searchNotFound: "Busqueda no encontrada",

	// nuevo 8
	fallowUs: "Síguenos",
	yes: "Si",
	no: "No",
	registerHere: "Regístrese aquí",
	ourClientsBelieve: "Nuestros clientes opinan",

	ecommercePlatform: "Plataforma de Ecommerce",
	whatIsYourEcommercePlatform:
		"Cual es el nombre de tu plataforma de ecommerce",

	// nuevo 10
	profileUpdated: "Perfil Actualizado",

	// nuevo 11
	ourCategories: "Nuestras Categorias",
	ourCategoriesHelp: "Mira nuestras últimas categorías",
	clearFilters: "Eliminar Filtros",

	// nuevo 12
	allBrands: "Podrás ver todas tus marcas",
	skip: "Saltar",
	searchBar: "Buscador",
	searchBarHelp: "Puedes encontrar cualquier producto que buscas",
	addProductsToCart: "Agregar productos al carrito",
	wishlistHelp:
		"Crea listas de productos para trabajarlos por colección o categoría",
	ordersHistory: "Historial de órdenes",
	ordersHistoryHelp: "Chequea tu historial de órdenes",
	tendencieTableHelp: "Crea tu inspiración en la mesa de tendencia",
	done: "Finalizar",
	clickHereToEnlarge: "Click para vista ampliada",
	help: "Ayuda",
	shoByCategory: "Compra por Categoria",
	sale: "Descuentos",
	gotoCart: "Ir al Carrito",

	caseOf: "Caja de",
	orderSummary: "Resumen del Pedido",

	escolar: "Escolar",
	hogar: "Hogar",
	wellness: "Wellness",
	religioso: "Religioso",
	navidad: "Navidad",
	allDepartments: "Todos los Departamentos",
	departments: "Departamentos",

	cookiePolicy: "Politica de Cookies",
	cookiePolicy2:
		"Usamos cookies (y otras tecnologías similares) para recopilar datos para mejorar su experiencia en nuestro sitio. Para utilizar nuestro sitio web, acepta la recopilación de datos como se describe en nuestra ",
	settings: "Ajustes",
	inCart: "En Carrito",
	seeProductInExibit: "Ver Producto Exhibido",
	color: "Color",
	videoRequested: "Video Solicitado",
	requestVideo: "Solicitar Video",
	request: "Solicitar",
	informationRequested: "Informacion Solicitada",
	generatePdf: "Generar PDF",
	search: "Buscar",

	priceStore: "Precio Tienda",
	configure: "Configurar",
	configureStorePrice: "Configurar precio tienda",
	adjustMultiplier:
		"Ajusta tu multiplicador y conversión de moneda (Precio Noritex X Multiplicador X Cambio de moneda = $Tu Precio)",
	timesNoritex: " Veces que será multiplicado su precio noritex",
	currency: "Moneda",
	currencyExchange: "Cambio de moneda",
	multiplier: "Multiplicador",
	yourPrice: "Tu Precio",
	conversion: "Conversion",
	helpPriceStore: "Calcula el precio del producto en tu tienda",

	volCapacity: "Capacidad Vol.",
	mechanism: "Mecanismo",
	notasEspeciales: "Carac. especiales",
	otherNotes: "Otras Notas",
	error: "Error por favor tratar de nuevo mas tarde",
	reachedMaximunRequest: "Ups! Solo puedes tener 10 solicitudes abiertas",

	errorFirstName: "Por favor ingrese un nombre",
	errorLastName: "Por favor ingrese un apellido",
	errorEmail: "Por favor ingrese un email válido",
	errorPhoneNumber: "Por favor ingrese un telefono de contacto válido",
	errorCountry: "Por favor ingrese un país válido",
	errorCity: "Por favor ingrese una ciudad válida",
	errorCompanyName: "Por favor ingrese el nombre de su empresa",
	errorCompanyPosition:
		"Por favor ingrese el nombre de su puesto en la empresa",
	errorCompanyType: "Por favor ingrese el tipo de negocio",
	errorCompanyWebsite:
		"Por favor ingrese el sitio web de la empresa si tiene. Si no tiene por favor colocar: no tenemos",
	errorCompanyType: "Por favor seleccione tipo de negocio",
	errorOption: "Por favor seleccione una opcion",
	errorAddress: "Por favor ingrese una dirección completa",
	errorProducts: "Por favor ingrese sus productos de interes",

	readyToBuyProductsOnline:
		"¿Listo para empezar a comprar al por mayor en línea?",
	registerToBuy: "Regístrate",
	unique1: "Productos exclusivos y diseñados para todos los gustos",
	unique3: "Productos de alta calidad y de buen precio para su tienda",
	unique2: "Productos innovadores y únicos seleccionados para su tienda",
	imageSearch: "Buscador de Imagenes",
	basedOnYourHistory: "Basado en tu historial de pedidos, te hemos preparado las siguientes recomendaciones",
	backToInfo: "Volver a Envio",
	continueWithPayments: "Continuar a Pagar",
	updateCartHappened:
		"¡Ups! Parece que tu carrito se ha actualizado, quieres comprobarlo antes de continuar?",
	pleaseContactSalesrepToConfirm:
		"Por favor contactar a su vendedor para confirmar el pedido",
	direct: "Directos ",
	hasProxy:
		"Parece que tiene un proxy instalado, nuestro sitio web no es compatible con proxies. Desactive su proxy antes de ingresar a nuestro sitio web o comuníquese con su departamento de TI para que lo desactive.",

	transit: "Transito",
	requestAccess: "Solicitar Acceso a Marca",
	pendingBrandRequest: "Solicitud Pendiente",
	deliveryDate: "Fecha de Llegada",
	products: "productos",
	pleseLoginToEnter: "Inicie sesión para añadir a la cesta",
	noPermissionToThisBrand: "Usted no tiene permiso para esta marca",
	win200: "Gana $6,000",
	isMissingImages: "Fotos pendientes por subir",
	unitPrice: "Precio Unitario",
	questionTitle: "Preguntas y respuestas de los clientes",
	leaveAQuestion: "Dejar una pregunta",
	sendAQuestion: "Enviar Pregunta",
	question: "Pregunta",
	response: "Respuesta",
	pleaseAddQuestion: "Porfavor agregue pregunta",
	"No realizo": "No realizo",
	exw: "EXW",
	fob: "FOB",

	claimExplain: "Para colocar un reclamo, seleccione los productos",
	claimExplainExpired:
		"Su factura se encuentra vencida, ya que los reclamos deben efectuarse dentro del tiempo estipulado en la Política de Reclamos y Despachos. De tener dudas, contacte a su vendedor.",
	alreadyClaimed: `Esta factura ya cuenta con un reclamo`,
	searchProductInOrder: "Buscar producto, coloque la referencia",
	"Recibe estos beneficios y más": "lo que nos destaca",
	"Ver todos los beneficios": "Ver todos los beneficios",
	billed: "Facturado",
	invoice: "Factura",
	"Account Status": "ESTADO DE CUENTA",
	Date: "Fecha",
	"Customer Information": "Información del Cliente",
	Name: "Nombre",
	"Nº CLIENT": "Nº CLIENTE",
	"Payment Terms": "Términos de Pago",
	Address: "Dirección",
	Phone: "Teléfono",
	Email: "Correo Electrónico",
	"Credit limit": "Límite de Crédito",
	Seller: "Vendedor",
	"Account statement details": "Detalles del estado de cuenta",
	"Expiration Date": "Fecha de Vencimiento",
	Type: "Tipo",
	"Billed Days": "Días Facturados",
	"Business Unit": "Unidad de Negocio",
	Bill: "Factura",
	Procedure: "Trámite",
	Order: "Pedido",
	Description: "Descripción",
	Debit: "Débito",
	Credit: "Crédito",
	Balance: "Saldo",
	Total: "Total",
	Account: "Cuenta",
	"Reserved orders": "Pedidos Reservados",
	"Traditional amount": "Monto tradicional",
	"Brand amount": "Monto marca",
	"Accounts receivable aging": "Antigüedad de cuentas por cobrar",
	"Payment instructions": "Instrucciones de pago",
	"Account Status Footer":
		"Es responsabilidad de cada cliente verificar su estado de cuenta al recibo del mismo salvo aviso contrario dentro de los quince (15) días siguientes a la fecha del estado, esta cuenta se considerará como correcta. Favor tomar en consideración saldos a favor que se mantengan por más de 2 años en la cuenta se procederá a limpieza de saldo.",
	Legend: "Leyenda",
	"Current Account": "Cuenta Corriente",
	"Beneficiary Client": "Cliente Beneficiario",
	"Intermediary Bank": "Banco Intermediario",
	"Beneficiary Bank": "Banco Beneficiario",
	"It seems this link has expired. Request a new link or get in touch with the seller for assistance.":
		"Parece que este enlace ha expirado. Solicite un nuevo enlace o comuníquese con el vendedor para obtener asistencia.",
	"Try refreshing again. If the information still doesn't appear, feel free to contact your seller.":
		"Intente refrescar nuevamente. Si la información aún no aparece, no dude en contactar a su vendedor.",

	"Request Error": "Error de solicitud",
	claimExplainExpired:
		"Su factura se encuentra vencida, ya que los reclamos deben efectuarse dentro del tiempo estipulado en la Política de Reclamos y Despachos. De tener dudas, contacte a su vendedor.",
	alreadyClaimed: `Esta factura ya cuenta con un reclamo`,
	searchProductInOrder: "Buscar producto, coloque la referencia",
	discountsByBrand: "Filtra descuentos por marca",
	"N/C": "Nota de crédito",
	"N/D": "Nota de débito",
	"MONTO TRADIC": "MONTO TRADICIONAL",
	"MONTO MARCA": "MARCA PRIVADA",
	Refresh: "Refrescar",
	"NTXBRAND-100": "HOGAR",
	"NTXBRAND-110": "NAVIDAD",
	"NTXBRAND-120": "RELIGIOSO",
	"NTXBRAND-150": "WELLNESS",
	"NTXBRAND-200": "CRISTALERIA",
	"NTXBRAND-300": "TEXTIL",
	"NTXBRAND-400": "DENIM",
	"NTXBRAND-500": "ESCOLAR Y OFICINA",
	"Outstanding balance": "Saldo pendiente",
	"Overdue balance": "Saldo vencido",
	"Featured categories Title": "Productos que Marcan la Tendencia",
	"Featured categories": "Categorías destacadas",
	"Categories that may also interest you.":
		"Categorías que también pueden interesarle.",
	noQuestionsYet: "Aún no hay preguntas. ¡Sé el primero en hacer una!",

	// leadForm changes
	entrepreneurship: "Emprendimiento",
	contactOptions: "¿Cómo prefiere ser contactado?",
	whatsappChat: "Chat de WhatsApp",
	whatsappCall: "Llamada de WhatsApp",
	howFoundUs: "¿Cómo nos encontró?",
	referredByCustomer: "Referido por Cliente",
	howFoundUsPlaceholder: "Por favor, especifique",
	conocenos: "Conócenos",
	links: {
		title: "Accesos Directos",
		l1: "Kit de Mercadeo",
		l2: "Descarga de imágenes",
		l3: "Preguntas Frecuentes",
		l4: "Arma tu colección",
		l5: "Guías y Tutoriales",
	},
	featuredBlog: "Visite Nuestro Blog de Contenido B2B",
	recommendationsTitle: "Recomendaciones Para Ti",
};
