export default function retry(fn) {
    let retries = 3;
    let interval = 300;
    let exponentialBackoff = true;

    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retries === 1) {
                        if (
                            localStorage.getItem("didErrorRefreshTest") !==
                            "true"
                        ) {
                            localStorage.setItem("didErrorRefreshTest", "true");
                            window.location.reload(true);
                        }
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, {
                        retries: retries - 1,
                        interval: exponentialBackoff ? interval * 2 : interval,
                    }).then(resolve, reject);
                }, interval);
            });
    });
}
